import React from 'react'
import './CardGame.css'

const Message = ({ destinataire, targetAudience, matchingPW }) => {
  if (targetAudience === 'p') {
    return (
      <>
        <span className="message-normal">
          Mindquest est devenue en 2022 un véritable laboratoire d’hybridation
          ludique.
        </span>
        <br />
        <span className="message-normal">
          <span
            className={`special-letter ${
              matchingPW[0] ? 'neon-green' : 'neon-red'
            }`}
          >
            P
          </span>
          our répondre
          <span
            className={`special-letter ${
              matchingPW[1] ? 'neon-green' : 'neon-red'
            }`}
          >
            à
          </span>
          des p
          <span
            className={`special-letter ${
              matchingPW[2] ? 'neon-green' : 'neon-red'
            }`}
          >
            r
          </span>
          oblématiques de sensibilisa
          <span
            className={`special-letter ${
              matchingPW[3] ? 'neon-green' : 'neon-red'
            }`}
          >
            t
          </span>
          ion, d’adhésion, d’information ou mêm
          <span
            className={`special-letter ${
              matchingPW[4] ? 'neon-green' : 'neon-red'
            }`}
          >
            e{' '}
          </span>
          de formatio
          <span
            className={`special-letter ${
              matchingPW[5] ? 'neon-green' : 'neon-red'
            }`}
          >
            n
          </span>
          , nous avons conçu et commercialisé pas moins de 6 solutions
          numériques.
        </span>
        <br />
        <span className="message-normal">
          Nous espérons que 2023 nous permettra de travailler ensemble sur des
          projets créatifs, passionnants et engagés.
        </span>
        <br />
        <span className="message-normal">
          MindQuest G
          <span
            className={`special-letter ${
              matchingPW[6] ? 'neon-green' : 'neon-red'
            }`}
          >
            a
          </span>
          mes est heureux de vous souha
          <span
            className={`special-letter ${
              matchingPW[7] ? 'neon-green' : 'neon-red'
            }`}
          >
            i
          </span>
          ter ses meilleurs vœux pou
          <span
            className={`special-letter ${
              matchingPW[8] ? 'neon-green' : 'neon-red'
            }`}
          >
            r
          </span>{' '}
          cette nouvelle année.
        </span>
        <br />
        <span className="message-normal">
          L’ équipe de MindQu
          <span
            className={`special-letter ${
              matchingPW[9] ? 'neon-green' : 'neon-red'
            }`}
          >
            e
          </span>
          st Games
        </span>
      </>
    )
  } else {
    return (
      <>
        <span className="message-normal">
          Félicit
          <span
            className={`special-letter ${
              matchingPW[0] ? 'neon-green' : 'neon-red'
            }`}
          >
            a
          </span>
          tions pour ce décryptag
          <span
            className={`special-letter ${
              matchingPW[2] ? 'neon-green' : 'neon-red'
            }`}
          >
            e
          </span>
          .
        </span>
        <br />
        <span className="message-normal">
          MindQuest Games vous présente ses meilleurs vœux pour 2023.
        </span>
        <br />
        <span className="message-normal">
          Une année qui vous permettra de partager
          <span
            className={`special-letter ${
              matchingPW[4] ? 'neon-green' : 'neon-red'
            }`}
          >
            t
          </span>
          outes nos inventions ludiques et de découv
          <span
            className={`special-letter ${
              matchingPW[3] ? 'neon-green' : 'neon-red'
            }`}
          >
            r
          </span>
          ir notr
          <span
            className={`special-letter ${
              matchingPW[5] ? 'neon-green' : 'neon-red'
            }`}
          >
            e
          </span>
          dernier jeu :
          <span
            className={`special-letter ${
              matchingPW[1] ? 'neon-green' : 'neon-red'
            }`}
          >
            L
          </span>
          es Infiltrés.
        </span>
        <br />
        <span className="message-normal">L’équipe de MindQuest Games</span>
      </>
    )
  }
}

export default Message
