import React, { useEffect, useMemo, useState } from 'react'
import './App.css'
import CardGame from './Components/CardGame'
import useWindowDimensions from './Lib/useWindowDimensions'
import { AppContext } from './Lib/ContextManager'
import Footer from './Components/Footer'
import Logo from './Components/Logo'
import Confetti from './Components/Confetti'
import { capitalize, identifyNavigator } from './Lib/FuncLib'

const App = () => {
  const [cheat, setCheat] = useState(false)
  const { height, width } = useWindowDimensions()
  const [isVerticalScreen, setIsVerticalScreen] = useState(false)
  const [screen_xs, set_screen_xs] = useState(false)
  const [screen_s, set_screen_s] = useState(false)
  const [screen_m, set_screen_m] = useState(false)
  const [screen_lg, set_screen_lg] = useState(false)
  const [gameEnded, setGameEnded] = useState(false)
  const [isTouchDevice, setIsTouchDevice] = useState(false)
  const [destinataire, setDestinataire] = useState('vous')
  const [targetAudience, setTargetAudience] = useState('g')

  const navigatorType = useMemo(() => {
    return identifyNavigator()
  }, [])

  useEffect(() => {
    let _destinataire = new URLSearchParams(window.location.search).get(
      'destinataire'
    )
    let _targetAudience = new URLSearchParams(window.location.search).get('t')
    let _cheat = new URLSearchParams(window.location.search).get('cheat')
    if (_destinataire) {
      setDestinataire(capitalize(_destinataire))
    }
    if (_cheat) {
      setCheat(true)
    }
    if (_targetAudience === 'p') {
      setTargetAudience('p')
    }
    function isTouchDevice() {
      return 'ontouchstart' in window && navigator.maxTouchPoints > 0
    }
    console.log(
      `%cUser agent : ${navigator.userAgent}`,
      'color: #DCDCAA; font-weight: bold'
    )
    setIsTouchDevice(isTouchDevice())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (width && height) {
      setIsVerticalScreen(Boolean(width / height <= 1))
      set_screen_xs(Boolean(width < 410))
      set_screen_s(Boolean(width >= 410 && width < 500))
      set_screen_m(Boolean(width >= 500 && width <= 620))
      set_screen_lg(Boolean(width > 620))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height])

  return (
    <AppContext.Provider
      value={{
        destinataire: destinataire,
        targetAudience: targetAudience,
        screenWidth: width,
        screenHeight: height,
        isVerticalScreen: isVerticalScreen,
        screen_xs: screen_xs,
        screen_s: screen_s,
        screen_m: screen_m,
        screen_lg: screen_lg,
        cheat: cheat,
        isTouchDevice: isTouchDevice,
        navigatorType: navigatorType,
      }}
    >
      <div className="App">
        <Logo isVerticalScreen={isVerticalScreen} />
        <div className="container">
          <div className="apph1 noselect">
            <span
              id="span1"
              className="title"
              style={{
                color: '#e55643',
                fontSize: screen_xs
                  ? '1rem'
                  : screen_s
                  ? '1.5rem'
                  : screen_m
                  ? '2rem'
                  : '2.5rem',
              }}
            >
              Message crypté
            </span>
            <span
              id="span2"
              className="title"
              style={{
                color: '#f1c83c',
                fontSize: screen_xs
                  ? '1.5rem'
                  : screen_s
                  ? '2rem'
                  : screen_m
                  ? '2.5rem'
                  : '3rem',
              }}
            >
              pour{' '}
              <span style={{ color: '#A1FF92' }}>
                {destinataire ? `${destinataire}` : 'vous'}
              </span>
            </span>
          </div>
          <CardGame onEnd={() => setGameEnded(true)} />
          <Footer />
        </div>
        {Boolean(gameEnded) && (
          <Confetti
            size={screen_xs ? 28 : screen_s ? 36 : screen_m ? 48 : 100}
          />
        )}
      </div>
    </AppContext.Provider>
  )
}

export default App
