import React from 'react'

const Social = () => {
  return (
    <>
      <hr color={'#c6fff9'} style={{ width: '100%' }} />
      <div className="easter-egg-footer">
        <a
          href="https://www.linkedin.com/company/mindquest-games/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/imgsrc/logos/linkedin.svg" alt="linked_in" />
        </a>
        <a
          href="https://www.facebook.com/MQGames/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/imgsrc/logos/fb.svg" alt="fb" />
        </a>
        <a
          href="https://www.instagram.com/mindquestgames/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/imgsrc/logos/insta.svg" alt="insta" />
        </a>
      </div>
    </>
  )
}

export default Social
