import React from 'react'

const Legend = ({ lastPair }) => {
  return (
    <>
      {lastPair?.title && (
        <p
          style={{
            margin: '0rem',
            fontWeight: 'bold',
            fontSize: '2rem',
          }}
        >
          {lastPair.title}
        </p>
      )}
      {lastPair?.description && (
        <p
          style={{
            marginBottom: '0rem',
            fontSize: '1.5rem',
          }}
        >
          {lastPair.description}
        </p>
      )}
      {lastPair?.legend && (
        <p
          style={{
            fontSize: '1.5rem',
          }}
        >
          {lastPair.legend}
        </p>
      )}
      {lastPair?.imgsrc && lastPair?.title && (
        <img
          src={lastPair.imgsrc}
          alt={lastPair.title}
          style={{
            marginTop: '15px',
            maxHeight: 'min(50vh, 600px)',
            maxWidth: '100%',
            borderRadius: '0.5rem',
            flexGrow: 0.5,
          }}
        />
      )}
    </>
  )
}

export default Legend
