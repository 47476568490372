import React, {
  useContext,
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
  /* useEffect, */
} from 'react'
import './Card.css'
import { AppContext, CardGameContext } from '../Lib/ContextManager'

const defaultPointers = {
  angle: 0,
  x: 0,
  y: 0,
  w: 0,
  h: 0,
}

const Card = ({ imgsrc, thumbnail, id, uid, hieroglyph, clickCallback }) => {
  const ac = useContext(AppContext)
  const cc = useContext(CardGameContext)
  const [pointers, setPointers] = useState(defaultPointers)
  const [hovering, setHovering] = useState(false)
  const [cardSize, setCardSize] = useState({
    width: 0,
    height: 0,
  })
  const cardRef = useRef(null)

  const isEliminated = useMemo(() => {
    return Boolean(cc.eliminatedCards.includes(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cc.eliminatedCards, id])

  const isFlipped = useMemo(() => {
    let uids = cc.currentCards.map((e) => e.uid)
    return Boolean(uids.includes(uid))
  }, [cc.currentCards, uid])

  useLayoutEffect(() => {
    if (cardRef.current) {
      setCardSize({
        width: cardRef.current.clientWidth,
        height: cardRef.current.clientHeight,
      })
    }
  }, [ac, cc.delay])

  const allowRotation = useMemo(() => {
    return !Boolean(
      isEliminated || ac.isTouchDevice || ac.navigatorType.isSafari || !hovering
    )
  }, [ac.isTouchDevice, ac.navigatorType.isSafari, hovering, isEliminated])

  const onMove = (e) => {
    if (!allowRotation) return
    const { offsetX, offsetY } = e.nativeEvent
    const w = e.target.clientWidth
    const h = e.target.clientHeight
    let dy = offsetX - h / 2 // h/2 = center
    let dx = offsetY - w / 2 // w/2 = center
    let theta = Math.atan2(dx, dy) // angle between cursor and center in RAD
    let angle = (theta * 180) / Math.PI - 90
    setPointers({
      angle: angle,
      x: offsetX,
      y: offsetY,
      w: w,
      h: h,
    })
  }

  const translateStyle = useMemo(() => {
    return {
      transform: `scale(1.1325) translateX(${
        allowRotation ? ((pointers.x - pointers.w / 2) / pointers.w) * -12 : 0
      }px) translateY(${
        allowRotation ? ((pointers.y - pointers.h / 2) / pointers.h) * -12 : 0
      }px)`,
    }
  }, [allowRotation, pointers])

  const rotationStyle = useMemo(() => {
    return {
      transform: `rotateY(${
        allowRotation ? ((pointers.x - pointers.w / 2) / pointers.w) * 40 : 0
      }deg) rotateX(${
        allowRotation ? ((pointers.y - pointers.h / 2) / pointers.h) * -40 : 0
      }deg)`,
    }
  }, [allowRotation, pointers])

  return (
    <div
      className="card-wrapper"
      onMouseEnter={() => {
        ac.screen_lg && setHovering(true)
      }}
      onMouseLeave={() => {
        ac.screen_lg && setHovering(false)
      }}
    >
      <div
        ref={cardRef}
        onMouseMove={onMove}
        className={`card ${
          ac.screen_xs
            ? 'card-xs'
            : ac.screen_s
            ? 'card-s'
            : ac.screen_m
            ? 'card-m'
            : 'card-lg'
        } ${isEliminated ? 'eliminated' : 'not-eliminated'} ${
          isEliminated
            ? ''
            : ac.screen_xs
            ? 'card-shadow-xs'
            : ac.screen_s
            ? 'card-shadow-s'
            : 'card-shadow-lg'
        }`}
        style={{
          background: isEliminated ? '' : `url(/imgsrc/back.jpg) center/100%`,
          ...rotationStyle,
        }}
        onClick={clickCallback}
      >
        <div className="card-front">
          {Boolean(!isEliminated && ac.cheat) && (
            <>
              <div className="card-title">{id}</div>
            </>
          )}
          <div
            className="card-shine"
            style={{
              background: `url(${thumbnail ?? imgsrc}) ${
                allowRotation ? 'center/110%' : 'center/100%'
              }`,
              transitionDuration: `125ms`,
              opacity: !isFlipped || isEliminated ? 0 : 1,
              ...translateStyle,
            }}
          />
          {!isEliminated && ac.screen_lg && hovering ? (
            <div
              className="card-shine"
              style={{
                background: `linear-gradient(${
                  pointers.angle
                }deg, rgba(255,255,255,${
                  isFlipped ? 0.33 : 0.75
                }) 0%,rgba(255,255,255, 0) 80%)`,
              }}
            />
          ) : (
            <></>
          )}
        </div>
        {cardSize.height && !isFlipped && !isEliminated && (
          <div className={`hieroglyph`}>
            <span
              className="anaglyph"
              style={{
                fontSize: `${cardSize.height * 0.3}px`,
              }}
            >
              {hieroglyph}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default Card
