import React from 'react'
import Legend from './Legend'

const Modal = ({ lastPair, dismiss, gameEnded }) => {
  return (
    <div
      id="legend"
      className={
        dismiss ? 'modal' : 'cardgame-container cardgame-secondary-container'
      }
      // className="cardgame-container cardgame-secondary-container"
      style={{
        transitionDuration: '750ms',
        opacity: Boolean(gameEnded) ? 0 : 1,
      }}
      onClick={(e) => {
        if (dismiss && e.target === e.currentTarget) {
          dismiss()
        }
      }}
    >
      <div
        className={dismiss ? 'modal-container' : ''}
        onClick={(e) => e.stopPropagation()}
      >
        {lastPair?.link ? (
          <a href={lastPair?.link} target="_blank" rel="noopener noreferrer">
            <Legend lastPair={lastPair} />
          </a>
        ) : (
          <Legend lastPair={lastPair} />
        )}
        {dismiss ? (
          <span
            className="modal-button"
            onClick={() => {
              dismiss()
            }}
          >
            FERMER
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Modal
