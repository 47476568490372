import React from 'react'

const Logo = ({ isVerticalScreen }) => {
  return (
    <div
      className={`logo ${isVerticalScreen ? 'logo_mobile' : 'logo_desktop'}`}
    >
      <a
        href="https://www.mindquest-games.fr/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/logo-500px-300x141.png" alt="mindquest" />
      </a>
    </div>
  )
}

export default Logo
