import React, { useLayoutEffect, useState } from 'react'
import './Confetti.css'
import { range, sleep } from '../Lib/FuncLib'

/**
 * @param {number} size number of confetti decorations
 */
const Confetti = ({ size }) => {
  const [renderConfetti, setRenderConfetti] = useState(true)
  const [fade, setFade] = useState(false)

  useLayoutEffect(() => {
    const fadeOut = async () => {
      await sleep(10000)
      setFade(true)
      await sleep(3000)
      setRenderConfetti(false)
    }
    fadeOut()
  }, [])

  return (
    <div className="confetti-wrapper" style={{ opacity: fade ? 0 : 1 }}>
      {renderConfetti &&
        range(size).map((e) => {
          return (
            <div
              key={e}
              className={`confetti-${e}`}
              style={{
                position: 'absolute',
                pointerEvents: 'none',
                overflow: 'hidden',
                opacity: 0,
              }}
            />
          )
        })}
    </div>
  )
}

export default Confetti
