export const sleep = (ms) => new Promise((res) => setTimeout(res, ms))
export const removeAt = (arr, idx) => {
  return [...arr.slice(0, idx), ...arr.slice(idx + 1, arr.length)]
}
export const removeFromArray = (arr, value) => {
  const index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}
export const randInt = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}
export const range = (n) => {
  return n >= 0 ? Array.from(Array(n), (_, i) => i + 1) : []
}
export const capitalize = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}
export const deepCopy = (obj) => {
  if (obj instanceof Object) {
    return JSON.parse(JSON.stringify(obj))
  } else {
    return obj
  }
}
export const fireAudioOnce = (params) => {
  const { source, onEnd, volume } = params
  if (!source) {
    console.error('NO AUDIO SOURCE : ')
    console.error(JSON.stringify(params))
  }
  const audio = new Audio(source)
  if (typeof volume === 'number') {
    audio.volume = volume
  }
  const errorHandler = (e) => {
    endingCallback()
    console.warn(`COULD NOT LOAD AUDIO SRC (${source}) : `, JSON.stringify(e))
  }
  const endingCallback = () => {
    if (onEnd) {
      onEnd()
    }
    cleanup()
  }
  const fireOnLoad = () => {
    const promise = audio.play()
    if (promise !== undefined) {
      promise
        .then(() => {})
        .catch((e) => {
          endingCallback()
          console.error(e)
        })
    }
  }
  const play = () => {
    audio.addEventListener('canplaythrough', fireOnLoad)
    audio.addEventListener('ended', () => endingCallback())
    audio.addEventListener('error', errorHandler)
    //audio.addEventListener("timeupdate", update)
    audio.load()
  }
  const cleanup = () => {
    audio.currentTime = 0
    audio.pause()
    audio.removeEventListener('canplaythrough', fireOnLoad)
    audio.removeEventListener('ended', () => endingCallback())
    audio.removeEventListener('error', errorHandler)
    //audio.removeEventListener("timeupdate", update)
    audio.remove()
  }
  play()
}
export const dynamicRound = (i, j) => {
  return Math.round(i * Math.pow(10, j)) / Math.pow(10, j)
}
export const identifyNavigator = () => {
  // Opera 8.0+
  // eslint-disable-next-line no-undef
  // const isOpera = (!!window.opr && !!opr?.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined'

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
    })(
      !window['safari'] ||
        (typeof safari !== 'undefined' && window['safari'].pushNotification)
    )

  // Internet Explorer 6-11
  // const isIE = /*@cc_on!@*/ false || !!document.documentMode

  // Edge 20+
  // const isEdge = !isIE && !!window.StyleMedia

  // Chrome 1 - 79
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

  // Edge (based on chromium) detection
  const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') !== -1

  // Blink engine detection
  // const isBlink = isChrome /* || isOpera */ && !!window.CSS
  return {
    isFirefox: isFirefox,
    isSafari: isSafari,
    // isEdge: isEdge,
    isChrome: isChrome,
    isEdgeChromium: isEdgeChromium,
  }
}
