import React, { useState, useCallback } from 'react'
import { sleep } from '../../Lib/FuncLib'
import { logos } from '../Game'

const Logos = () => {
  const [hoverID, setHoverID] = useState('')
  const [transitioning, setTransitioning] = useState(false)

  const onHover = useCallback(
    async (id) => {
      if (transitioning) {
        return
      }
      setHoverID(id)
      setTransitioning(true)
      await sleep(150)
      setTransitioning(false)
    },
    [transitioning]
  )
  return (
    <div className="logo-container" onMouseLeave={() => setHoverID('')}>
      {logos.map((logo) => {
        return (
          <div
            className="logo-item"
            key={logo.id}
            onMouseEnter={() => onHover(logo.id)}
            style={{
              ...logo.style,
              cursor: logo.link ? 'pointer' : 'default',
              margin: hoverID === logo.id ? '5px' : '1rem',
            }}
            onClick={() => {
              if (logo.link) {
                if (logo.link.startsWith('mailto:')) {
                  var mail = document.createElement('a')
                  mail.href = logo.link
                  mail.click()
                  mail.remove()
                } else {
                  window.open(logo.link, '_blank')?.focus()
                }
              }
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              {...logo.image}
              style={{
                ...logo.image.style,
                ...(hoverID === logo.id
                  ? { maxWidth: '125px', maxHeight: '125px' }
                  : {}),
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Logos
